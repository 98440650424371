import Link from "next/link";
import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import styles from "../../styles/ShopByPrice.module.css";
function ShopByPrice() {
  const handleClick = (value: number) => {};

  const createParams = (from: any, to: any, page: number) => {
    let paramss = new URLSearchParams();
    paramss.append("priceFrom", `${from}`);
    paramss.append("priceTo", `${to}`);
    paramss.append("page", `${page}`);

    return `${paramss}`;
  };
  return (
    <div
      className=" py-2 "
      style={{
        marginBottom: "50px",
        backgroundColor: "#ECF5FD",
      }}
    >
      <div className="row title-row ">
        <div className="col">
          <h4 className="text-center">Budget Deals</h4>
        </div>
      </div>
      <div className="row g-3 align-items-center justify-content-center px-2 main">
        <div className="col-6 col-sm-4 col-md-3 col-lg-3">
          <Link href={`/products?${createParams(0, 5000, 1)}`}>
            <div
              className={` rounded-3 ${styles.p_card} d-flex justify-content-center align-items-center`}
              style={{ backgroundColor: "rgb(188, 203, 253)" }}
            >
              <div className="m-2 fw-500">Under Rs. 5K</div>
            </div>
          </Link>
        </div>
        <div className="col-6 col-sm-4 col-md-3 col-lg-3">
          <Link href={`/products?${createParams(5000, 10000, 1)}`}>
            <div
              className={` rounded-3 ${styles.p_card} d-flex justify-content-center align-items-center`}
              style={{ backgroundColor: "#bebcfc" }}
            >
              <div className="m-2 fw-500">Rs. 5K - Rs. 10K</div>
            </div>
          </Link>
        </div>
        <div className="col-6 col-sm-4 col-md-3 col-lg-3">
          <Link href={`/products?${createParams(10000, 20000, 1)}`}>
            <div
              className={` rounded-3 ${styles.p_card} d-flex justify-content-center align-items-center`}
              style={{ backgroundColor: "#bfacfb" }}
            >
              <div className="m-2 fw-500">Rs. 10K - Rs. 20K</div>
            </div>
          </Link>
        </div>
        <div className="col-6 col-sm-4 col-md-3 col-lg-3">
          <Link href={`/products?${createParams(20000, 30000, 1)}`}>
            <div
              className={` rounded-3 ${styles.p_card} d-flex justify-content-center align-items-center`}
              style={{ backgroundColor: "#c09cfa" }}
            >
              <div className="m-2 fw-500">Rs. 20K - Rs. 30K</div>
            </div>
          </Link>
        </div>
        <div className="col-6 col-sm-4 col-md-3 col-lg-3">
          <Link href={`/products?${createParams(30000, 40000, 1)}`}>
            <div
              className={` rounded-3 ${styles.p_card} d-flex justify-content-center align-items-center`}
              style={{ backgroundColor: "#c18cf9" }}
            >
              <div className="m-2 fw-500">Rs. 30K - Rs. 40K</div>
            </div>
          </Link>
        </div>
        <div className="col-6 col-sm-4 col-md-3 col-lg-3">
          <Link href={`/products?${createParams(40000, 50000, 1)}`}>
            <div
              className={` rounded-3 ${styles.p_card} d-flex justify-content-center align-items-center`}
              style={{ backgroundColor: "#c37df8" }}
            >
              <div className="m-2 fw-500">Rs. 40K - Rs. 50K</div>
            </div>
          </Link>
        </div>

        <div className="col-6 col-sm-4 col-md-3 col-lg-3">
          <Link href={`/products?${createParams(50000, 60000, 1)}`}>
            <div
              className={` rounded-3 ${styles.p_card}  d-flex justify-content-center align-items-center`}
              style={{ backgroundColor: "#c46df7" }}
            >
              <div className="m-2 fw-500">Rs. 50K - Rs. 60K</div>
            </div>
          </Link>
        </div>
        <div className="col-6 col-sm-4 col-md-3 col-lg-3">
          <Link href={`/products?${createParams(60000, 1000000, 1)}`}>
            <div
              className={` rounded-3 ${styles.p_card} d-flex justify-content-center align-items-center `}
              style={{ backgroundColor: "#c55df6" }}
            >
              <div className="m-2 fw-500">Above Rs. 60K</div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ShopByPrice;
