// import { ProductCard } from "../../components/ProductCard";
"use client";

import Image from "next/image";
import React, { useState } from "react";
import styles from "../../styles/FeaturedCategories.module.css";

import { useRouter } from "next/navigation";
import { sendGTMEvent } from "@next/third-parties/google";
import Link from "next/link";
import {
  accessoriesCircle,
  desktopCircle,
  desktopImage,
  headPhoneImage,
  laptop,
  laptopCircle,
  mobileCircle,
  MobilePic,
  tabletCircle,
  tabletImage,
  TV,
  tvCircle,
} from "../../../public/images";
import { motion } from "framer-motion";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useUserStore } from "../../store/User";
import {
  fetchRecommendedListing,
  getFeaturedListings,
} from "../../app/apis/getApis";
import { ServerInsertedHtml } from "next/dist/server/future/route-modules/app-page/vendored/contexts/entrypoints";
import { marketplaceCdn } from "../../utility/env";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination, Virtual } from "swiper/modules";
import { replaceSpecialCharacters } from "../../utility/Images/utils";
import { Col, Row } from "react-bootstrap";

const FeaturedCategories = () => {
  const [sliderRef, setSliderRef] = useState<any>(null);
  const { location } = useUserStore((state: any) => state);
  const [items, setItems] = useState([]);
  const router = useRouter();

  const getItems = useQuery({
    queryKey: ["featuredListing"],
    queryFn: () => getFeaturedListings("", "home-top"),
    refetchOnWindowFocus: false,
    cacheTime: 0,

    onSuccess: (data) => {
      setItems(
        data.data.body.data.map((item: obj) => {
          return {
            listingId: item.listing_id,
            title: item.listing_title,
            quantity: item.listed_quantity,
            sale_price: item.online_price,
            discount: item.online_discount,
            created_at: item.created_at,
            updated_at: null,
            location_id: item.location_id,
            item: item.item,
            effectivePrice: item?.effective_price,
          };
        })
      );
    },
  });

  const categories = [
    { id: "1", name: "Laptops", img: laptopCircle },

    {
      id: "2",
      name: "Mobiles",
      img: mobileCircle,
    },
    { id: "3", name: "Tablets", img: tabletCircle },
    { id: "4", name: "TV/Monitors", img: tvCircle },

    { id: "5", name: "Desktops ", img: desktopCircle },

    { id: "6", name: "Accessories", img: accessoriesCircle },
  ];

  return (
    <div>
      <div
        className={`d-flex gap-2 mx-2 mb-2 py-1 bg-white mb-4  justify-content-center ${styles.mainDiv} `}
      >
        <Row
          style={{ background: "white", borderRadius: "40px", padding: "10px" }}
          className={`d-flex gap-3 border-right  justify-content-center w-100 ${styles.categoryDiv}`}
        >
          {categories.map((item, index) => {
            return (
              <Col
                xs="3"
                md="3"
                lg="1"
                className={`d-flex  flex-column align-items-center ${styles.itemDiv}`}
              >
                <div
                  as={Link}
                  onClick={() => {
                    sendGTMEvent({
                      event: "Category-Clicked",
                      value: item.name,
                      location: "Home",
                      url:
                        process.env.NEXT_PUBLIC_MARKETPLACE_URL +
                        `products?params=category_id=${item.id}`,
                    });
                    router.push(`/products?category_id=${item.id}`);
                  }}
                  className={`align-items-center cursor-pointer fw-500 rounded-circle  ${styles.catImg} `}
                >
                  <Image
                    alt={MobilePic}
                    quality={100}
                    height={356}
                    width={356}
                    src={item.img}
                    className="me-2 rounded-circle
"
                  />
                </div>
                <span
                  style={{ maxWidth: "100px" }}
                  className={`mx-3 fs-14 text-center  ${styles.label}`}
                >
                  {item.name}
                </span>
              </Col>
            );
          })}
        </Row>
      </div>
      <div className={styles.feature_div}>
        <div className="main">
          <div className={` ${styles.container} px-sm-1`}>
            {/* <h1 className={`pb-2 ${styles.rPe_h1} `}> Categories</h1> */}
            <div className={` w-100 gap-2`}>
              <div
                className={` gap-2    overflow-hidden  px-2 ${styles.item_div}`}
              >
                <Swiper
                  modules={[Virtual, Navigation, Pagination, Autoplay]}
                  slidesPerView={6}
                  centeredSlides={false}
                  spaceBetween={10}
                  
                  navigation={true}
                  autoplay={{
                    delay: 3500,
                    disableOnInteraction: false,
                  }}
                  virtual
                  className="mySwiper w-100"
                  style={{ zIndex: "auto" }}
                >
                  {getItems?.data?.data?.body?.data
                    ?.reduce((result: any[][], item: any, index: number) => {
                      if (index % 2 === 0)
                        result.push([item]); // Start a new group every 2 items
                      else result[result.length - 1].push(item); // Add to the current group
                      return result;
                    }, [])
                    .map((group: any[], groupIndex: number) => (
                      <SwiperSlide
                        key={groupIndex}
                        className={`mb-2 ${styles.cardColumn}`}
                      >
                        <div
                          className={`${styles.cardGrid} d-flex flex-column`}
                        >
                          {/* Render the first row */}
                          <div className="d-flex">
                            {group[0] && (
                              <RecomendedCard
                                image={group[0].item.itemImages.img0}
                                title={group[0].listing_title}
                                price={group[0].effective_price}
                                salePrice={group[0].online_price}
                                listingId={group[0].listing_id}
                              />
                            )}
                          </div>
                          {/* Render the second row */}
                          <div className="d-flex">
                            {group[1] && (
                              <RecomendedCard
                                image={group[1].item.itemImages.img0}
                                title={group[1].listing_title}
                                price={group[1].effective_price}
                                salePrice={group[1].online_price}
                                listingId={group[1].listing_id}
                              />
                            )}
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturedCategories;

const RecomendedCard = ({ image, title, price, salePrice, listingId }: any) => {
  const router = useRouter();
  return (
    <div
      onClick={() => {
        router.push(
          `/products/${replaceSpecialCharacters(title.replaceAll(" ", "-").replaceAll("/", "").replaceAll("--", "")).trim()}-${listingId}`
        );
      }}
      className={` ${styles.recomendedCard} d-flex gap-2  position-relative  bg-white w-100 border  align-items-center cursor-pointer `}
    >
      <div
        className={`text-center position-absolute top-0  d-flex justify-content-center align-items-center ${styles.featured}`}
        style={{
          // borderTopLeftRadius: "7px",
          borderBottomLeftRadius: "7px",
          // borderBottomRightRadius: "7px",
          right: "0",
        }}
      >
        <p
          className="m-0 py-0 text-white fw-bold p-2"
          style={{ fontSize: "11px", cursor: "pointer" }}
        >
          Hot
        </p>
      </div>
      <Image
        height={74}
        width={74}
        className={`rounded-2  ${styles.recomCard} `}
        alt="productimg"
        src={`${marketplaceCdn}/${image}`}
      />
      <p className={"fs-14 fw-500 mb-0   "}>
        {" "}
        Rs {price}
        {salePrice > 0 && Number(salePrice) !== Number(price) && (
          <p
            className={
              "fs-12 mb-0 fw-400 text-danger text-decoration-line-through  "
            }
          >
            {" "}
            Rs {salePrice}
          </p>
        )}
      </p>
    </div>
  );
};
